import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/reset.css";
import "@/plugins/eruda";
import "./components/toast/toast.css";
import Toast from "./components/toast/toast";
Vue.use(Toast, {
  defaultType: "center",
  duration: "2000"
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
