import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/payhelp"
  },
  {
    path: "/payhelp",
    name: "payhelp",
    component: () => import("../views/payhelp/index")
  },
  {
    path: "/endorder",
    name: "endorder",
    component: () => import("../views/payhelp/endorder")
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/patment/index")
  },
  {
    path: "/invalid",
    name: "invalid",
    component: () => import("../views/patment/invalid")
  },
  {
    path: "/success",
    name: "success",
    component: () => import("../views/patment/success")
  }
];

const router = new VueRouter({
  routes
});

export default router;
